/**
 * Counter - animates number counting.
 *
 * 	- Takes an element that must have a class of 'counter-js'.
 * 	- Counting doesn't begin until the element is scrolled into view.
 * 	- Digits of the number are wrapped in a span for styling purposes
 * 	  and commas are added every three characters after the decimal.
 *
 *
 * @usage:
 *
 * 		Create an element that has a class of 'counter-js' and add a
 * 		data attribute of end to be used as the number to count to.
 *
 * 		Other data attributes can be used to control various aspects
 * 		of the animation.
 *
 * 		data attributes:
 *
 * @data end - the number to count to.
 * @data start - the number to begin the count with. Defaults to 0.
 * @data decimal_places - the number of decimal places to use in the
 * 			count. Defaults to 0.
 * @data time - the length of time in milliseconds to complete the
 * 			count. Defaults to 3 seconds.
 * @data easing - the easing function to use in the animation.
 * 			Defaults to 'swing'.
 *
 * @example:
 *
 *  	<span class="counter-js" data-start="1.0" data-end="1234.5" data-time="3500" data-decimal_places="1" data-easing="easeInBack">
 *  		<span class="digit">1</span>
 *  		<span class="digit punctuation">,</span>
 *  		<span class="digit">2</span>
 *  		<span class="digit">3</span>
 *  		<span class="digit">4</span>
 *  		<span class="digit punctuation">.</span>
 *  		<span class="digit">5</span>
 *  	</span>
 *
 *
 * DBS>Interactive
 * @author Harold Bradley III
 */



/**
 * Counter object
 *
 * @param element - the DOM element that will contain the counter numbers
 *
 * @return Counter - an instance of the object
 */
function Counter( element ) {

	// Create a closure to refer to this in event handlers
	var counter = this;

	this.element = element;
	this.$element = jQuery( element );

	if ( ! this.$element.data( 'end' ) ) {
		console.warn( '[Counter]: Cannot proceed without data attribute end on element: ', this.element );
	}

	this.start = this.$element.data( 'start' ) === undefined ? 0 : this.$element.data( 'start' );
	this.end = this.$element.data( 'end' );
	this.decimal_places = this.$element.data( 'decimal_places' ) === undefined ? 0 : this.$element.data( 'decimal_places' );
	this.time = this.$element.data( 'time' ) === undefined ? 3000 : this.$element.data( 'time' );
	this.easing = this.$element.data( 'easing' ) === undefined ? 'easeOutQuint' : this.$element.data( 'easing' );

	// Are we currently counting?
	this.counting = false;


	/**
	 * Begins the counting animation. This is where the magic happens.
	 */
	this.begin = function() {
		counter.counting = true;
		counter.$element
			.prop( 'number', counter.start )
			.animate( { number: counter.end, },
				{
					duration: counter.time,
					easing: counter.easing,
					step: function ( number ) { counter.update_count( number ); }
				}
			);
	};


	/**
	 * Formats the counter number and casts to a string
	 *
	 * @param number - an integer to format
	 */
	this.format = function( number ) {
		return number.toFixed( this.decimal_places ).toString();
	};

	// Reset counter with start number on init
	this.show_number( this.format( this.start ), this.element );


	/**
	 * Displays the current count with number
	 *
	 * @param number - the integer to update
	 */
	this.update_count = function( number ) {
		this.show_number( this.format( number ), this.element );
	};



	/**
	 * Scrolling Methods
	 * To wait until element is visible before beginning the count.
	 */

	var window_height = $( window ).height();
	this.top = this.$element.offset().top;

	// Reset element's top at every resize.
	jQuery( window ).resize( function() {
		window_height = $( window ).height();
		counter.top = counter.$element.offset().top;
	});


	/**
	 * If it's already in view, begin counting now.
	 */
	if ( $( window ).scrollTop() < counter.top && counter.top < window_height + $( window ).scrollTop() ) {
		this.begin();
		return this;
	}

	// Otherwise set up the scroll handler...

	/**
	 * Scroll event handler - runs the begin_count function when
	 * element is scrolled into view.
	 */
	jQuery( window ).scroll( function( event ) {

		// Don't do anything if already counting
		if ( counter.counting ) { return; }

		// Begin counting when scrolled into view
		if ( $( this ).scrollTop() < counter.top && counter.top < window_height + $( this ).scrollTop() ) {
			counter.begin();
		}
	});

	return this;
}


/**
 * A method that loops through the digits of a number to format the
 * number in order to display it on the display_element.
 *
 * @param number (string) - the MUST be a string representing the
 * 			number to display
 * @param display_element - the element used to hold the number
 */
Counter.prototype.show_number = function ( number, display_element ) {

	// The number to display formatted as a string
	var number = number;

	// The element on which to display the number.
	var element = display_element;


	// Empty the current contents
	jQuery( element ).empty();


	// Pointers to the current and previous digits.
	var current_digit = null;
	var previous_digit = null;

	// Boolean that keeps track of when the number is part of
	// the decimal. Used to determine when to start tracking
	// for commas.
	var in_decimal = number.includes( '.' );

	// Integer to keep track of where to place commas
	var digit_counter = -1;  // Will be incremented to 0 for first character


	/**
	 * Function that determines whether or not a comma is necessary before a
	 * particular digit. Used when looping through the digits of a number.
	 *
	 * @param digit - single character representing the digit to test
	 *
	 * @return bool - returns true if the character needs a comma before it.
	 */
	var needs_comma_before = function( digit ) {
		if ( in_decimal ) { return false; }

		if ( digit === '.' ) {
			in_decimal = false;  // no longer in decimal
			return false;
		}

		// Don't need a comma just yet...
		if ( digit_counter < 2 ) { digit_counter++; return false; }

		// Now we need a comma:
		digit_counter = 0;  // Reset counter
		return true;
	};


	/**
	 * Function to insert a comma digit.
	 *
	 * Relies on closure context to set current_digit and previous_digit.
	 */
	add_comma = function() {
		previous_digit = current_digit;  // Save current digit as previous digit
		current_digit = create_digit( ',' );  // Create new comma digit
		element.insertBefore( current_digit, previous_digit );  // Insert it before previous digit
	};


	/**
	 * create_digit - Sets up the elements and classes necessary for a digit
	 * or punctuation.
	 *
	 * @param digit - single character to add
	 *
	 * @return container - an html container that contains the digit
	 */
	create_digit = function( digit ) {
		var container = document.createElement( 'span' );
		var contents = document.createTextNode( digit );

		container.className = 'digit';
		if ( digit === '.' || digit === ',' ) { container.className = 'digit punctuation'; }

		// Add contents to container
		container.appendChild( contents );

		return container;
	};

	// Display Loop
	// Loop *backwards* through digits adding them to the display element
	// and adding commas where appropritate.
	for ( var i = number.length - 1; i > -1; i-- ) {
		if ( needs_comma_before( number[i] ) ) { add_comma(); }
		previous_digit = current_digit;  // Save current digit as previous digit
		current_digit = create_digit( number[i] );  // Create new current digit
		element.insertBefore( current_digit, previous_digit );  // Insert it before previous digit
	}
}

window.addEventListener( 'load', function() {
	jQuery( '.counter-js' ).each( function () {
		var counter = new Counter( this );
	});
});
